import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'basic',
    icon: <PlanFreeIcon />,
    price: 65.99,
    caption: 'ahorra $20 al año',
    lists: [
      { text: '30 dias gratis', isAvailable: true },
      { text: '3 tableros', isAvailable: true },
      { text: 'Hasta 5 miembros del equipo', isAvailable: false },
      { text: 'Seguridad avanzada', isAvailable: false },
      { text: 'Permisos y flujos de trabajo', isAvailable: false },
    ],
    labelAction: 'Seleccionar Basic',
  },
  {
    subscription: 'starter',
    icon: <PlanStarterIcon />,
    price: 83.99,
    caption: 'ahorra $40 al año',
    lists: [
      { text: '30 dias gratis', isAvailable: true },
      { text: '3 tableros', isAvailable: true },
      { text: 'Hasta 5 miembros del equipo', isAvailable: true },
      { text: 'Seguridad avanzada', isAvailable: false },
      { text: 'Permisos y flujos de trabajo', isAvailable: false },
    ],
    labelAction: 'Seleccionar Starter',
  },
  {
    subscription: 'Professional',
    icon: <PlanPremiumIcon />,
    price: 47.0,
    caption: 'ahorra $60 al año',
    lists: [
      { text: '30 dias gratis', isAvailable: true },
      { text: '3 tableros', isAvailable: true },
      { text: 'Hasta 5 miembros del equipo', isAvailable: true },
      { text: 'Seguridad avanzada', isAvailable: true },
      { text: 'Permisos y flujos de trabajo', isAvailable: true },
    ],
    labelAction: 'Seleccionar Premium',
  },
];
