import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  newAgent: null,
  agentDetails: {
    properties: [],
    clients: [],
  },
  office: {
    name: '',
    address: '',
    phoneNumber: '',
  },
  offices: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.offices = [];
      state.error = null;
      state.office = {
        name: '',
        address: '',
        phoneNumber: '',
      };
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getOffice(state, action) {
      state.isLoading = false;
      state.offices = action.payload.offices;
    },
    getAgent(state, action) {
        state.isLoading = false;
        state.newAgent = action.payload.user
    },
    getAgentDetails(state, action) {
        state.isLoading = false;
        state.agentDetails = {
            properties:  action.payload.user.properties,
            clients:  action.payload.user.clients
        }
    }
  },
});

export const {
  getOffice
} = slice.actions;
// Reducer
export default slice.reducer;

export function newOffice(body) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/office/new-office', body);
      dispatch(slice.actions.getOffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOffices() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get('/api/office/list-office');
      dispatch(slice.actions.getOffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNewAgent(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.post('/api/account/create-new-agent', body);
      dispatch(slice.actions.getAgent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAgent(id) {
  return async () => {
    try {
      const response = await axios.get(`/api/account/delete-agent/${id}`);
      dispatch(slice.actions.getOffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentDetails(id) {
  return async (dispatch) => {
    try {
    
      const response = await axios.get(`/api/account/details-agent/${id}`);
      dispatch(slice.actions.getAgentDetails(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
