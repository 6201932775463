import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../utils/axios';
//
import { dispatch } from './store';

const initialState = {
  isLoading: false,
  error: null,
  countries: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET PRODUCTS
    getContries(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },
    getActualCountry(state, action) {
      state.country.name = action.payload.name;
      state.country.code = action.payload.code;
      state.country.captial = action.payload.captial;
    }
  },
});

// Reducer
export default slice.reducer;

export function getContries() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/account/countries/');
      const elements = response.data.countries.filter(country => country.id !== 'CN' && country.id !== 'CBT' && country.id !== 'PT' && country.id !== 'COL');
      dispatch(slice.actions.getContries(elements));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
