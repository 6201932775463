import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../utils/axios';
//
import { dispatch } from './store';

const initialState = {
  isLoading: false,
  error: null,
  posts: [],
  post: null,
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.post = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // NEW POST
    newPost(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },
    // GET POSTS
    getPosts(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },
    // remove post
    removePost(state, action) {
      state.isLoading = false;
      state.posts = state.posts.filter(post => post.id !== action.payload);
    }
  },
});

// Reducer
export default slice.reducer;

// new post
export function newPost(post) {
  const formData = new FormData();
  formData.append('image', post.cover);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const upload = await axios.post('/api/account/upload-image-post', formData);
      const newPost = {
        title: post.title,
        description: post.description,
        content: post.content,
        cover: upload.data.urlImage,
        tags: post.tags,
        publish: post.publish,
        comments: post.comments,
      };
      const response = await axios.post('/api/account/new-post', newPost);
      dispatch(slice.actions.newPost(response.data.post));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// upload post
export function uploadPost(id,post) {
  const cover = {
    url: '',
  };
  return async () => {
   
    try {
      if (post.cover.preview) {
        const formData = new FormData();
        formData.append('image', post.cover);
        const upload = await axios.post('/api/account/upload-image-post', formData);
        cover.url = upload.data.urlImage;
      } else {
        cover.url = post.cover;
      }
      const newPost = {
        title: post.title,
        description: post.description,
        content: post.content,
        cover: cover.url,
        tags: post.tags,
        publish: post.publish,
        comments: post.comments,
      };
      const response = await axios.post(`/api/account/update-post/${id}`, newPost);
      dispatch(slice.actions.newPost(response.data.post));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// uploadImagePost
export function uploadImagePost(file) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/account/upload-image-post', file);
      dispatch(slice.actions.newPost(response.data.post));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// new comment post
export function newCommentPost(id, data) {
  return async () => {
    try {
      const response = await axios.post(`/api/account/new-comment-post/${id}`, data);
      dispatch(slice.actions.newPost(response.data.post));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// responseCommentPost
export function responseCommentPost(postId, commentId, data) {
  return async () => {
    try {
      const response = await axios.post(`/api/account/response-comment-post/${postId}/${commentId}`, data);
      dispatch(slice.actions.newPost(response.data.post));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// getPost
export function getPost(title) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-post/${title}`);
      dispatch(slice.actions.newPost(response.data.post));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// getPostsByUser
export function getPostsByUser() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-posts-by-user/`);
      dispatch(slice.actions.getPosts(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// reset post
export function resetPost() {
  return async () => {
    dispatch(slice.actions.newPost(null));
  };
}
// remove post
export function removePost(id) {
  return async () => {
    dispatch(slice.actions.removePost(id));
  };
}