import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { AnimatePresence } from 'framer-motion';
// @mui
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { LoadingButton } from '@mui/lab';

import { List, Stack, Grid, Button } from '@mui/material';
import ListItemUpload from './ListItemUpload';

// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  uploadImages: PropTypes.func,
  loading: PropTypes.bool,
  orderUpdate: PropTypes.func,
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 2,
  margin: `0 ${2}px 0 0`,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  display: 'flex',
  padding: grid,
  width: itemsLength * 95 + 16,
});

export default function MultiFilePreview({ updateFiles, files, onRemove, onRemoveAll, uploadImages, loading, orderUpdate }) {
  const hasFile = files.length > 0 && !files.every((file) => isString(file));
  const [images, setImages] = React.useState(files);

  const handleUploadImages = () => {
    if (uploadImages) {
      uploadImages(images);
    }
  };

  const onDragEnd = (result) => {
    const newItems = [... images];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination && result.destination.index, 0, removed);
      setImages(newItems);
      orderUpdate(newItems);
  };

  React.useEffect(() => {
    if (files.length > 0 && images.length === 0) {
      setImages(files);
    }
  }, [files, images]);

  const handleRemove = (file) => {
    // validate 4 first characters
    const isImage = file.substring(0, 4) === 'http';
    if (isImage) {
      setImages(images.filter((item) => item !== file))
      onRemove(file)
    } else {
      setImages(images.filter((item) => item.preview !== file))
    }
  }

  return (
    <>
      <div style={{ overflow: 'scroll', height: 150 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver, images.length)}
                {...provided.droppableProps}
              >
                {images.map((item, index) => {
                 const { key, name, size, preview } = getFileData(item);
                  return (
                    <Draggable key={isString(item) ? item : preview} draggableId={isString(item) ? item : preview} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                       <ListItemUpload 
                          item={isString(item) ? item : preview}
                          onRemove={handleRemove}
                       />
                      </div>
                    )}
                  </Draggable>
                  )
 } )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remover todo
          </Button>
          <LoadingButton size="small" variant="contained" onClick={handleUploadImages} loading={loading}>
            Subir imagenes
          </LoadingButton>
        </Stack>
      )}
    </>
  );
}