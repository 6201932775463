import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  sortBy: 'all',
  page: 0,
  pages: 0,
  questions: [],
  events: [],
  questionsPush: {
    isUnRead: [],
    isRead: [],
  },
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  errorDetails: null,
  propertiesImperium: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.properties;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
      state.errorDetails = null;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;

    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
    
    resetProduct(state) {
      state.product = null;
    },
    getQuestions(state, action) {
      state.questions = action.payload;
    },
    getQuestionsPush(state, action) {
      state.questionsPush = action.payload;
    },
    readQuestions(state, action) {
      const questions = state.questionsPush.isUnRead.find((question) => question._id === action.payload)
      if (questions) {
        questions.isUnRead = false;
        state.questionsPush.isUnRead = state.questionsPush.isUnRead.filter((question) => question._id !== action.payload);
        state.questionsPush.isRead = [...state.questionsPush.isRead, questions];
      }
   },
   propertyMeli(state, action) {
    state.product = action.payload.property;
  },
  getEvents(state, action) {
    state.events = action.payload;
  },
  hasErrorDetails(state, action) {
    state.errorDetails = action.payload;
  },
  getPropertiesImperium(state, action) {
    action.payload.forEach((property) => {
      state.propertiesImperium.push(property);
    });
  }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProperties(value,sortBy) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-properties?page=${ value === 0 ? 0 :value - 1}&status=${sortBy ?? 'all'}`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.resetProduct());
    try {
      const response = await axios.get(`/api/account/get-property/${id}`);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasErrorDetails(error));
    }
  };
}

export function createNote(note) {
  return async () => {
    try {
      const response = await axios.post('/api/account/properties/create-note/', note);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProperty(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/delete-property/${id}`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}
export function resetProduct() {
  return async () => {
    dispatch(slice.actions.resetProduct());
  }
}
// update product meli
export function updatePropertyDataMeli(id, product) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/account/properties/update-update-property-data-meli/${id}`, product);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function updatePropertyState(id, data) {
  return async () => {
    try {
      const response = await axios.post(`/api/account/properties/update-property-state/${id}`, data);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getQuestionsForProperty(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/portal/agent/questions-for-property/${id}`);
      dispatch(slice.actions.getQuestions(response.data.questions));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function saveResponseQuestions(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/portal/agent/questions-save-response/${id}`, {
        response: data
      });
      dispatch(slice.actions.getQuestions(response.data.questions));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getQuestionsForAgent() {
  return async () => {
    try {
      const response = await axios.get(`/api/portal/agent/questions-for-agent/`);
      dispatch(slice.actions.getQuestionsPush(response.data.questions));
    } catch (error) {
      console.error(error);
     // dispatch(slice.actions.hasError(error));
    }
  }
}

export function readQuestions(id) {
  return async () => {
    dispatch(slice.actions.readQuestions(id));
    try {
       await axios.post(`/api/portal/agent/update-question/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

// add interested customer
export function addInterestedCustomer(id,data) {
  return async () => {
    try {
      const response = await axios.post(`/api/account/add-interested-customer/${id}`, data);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// deleteInterestedClient
export function deleteInterestedClient(id,data) {
  return async () => {
    try {
      const response = await axios.post(`/api/account/delete-interested-customer/${id}`,data);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function favoriteProperty(id,data) {
  return async () => {
    try {
      const response = await axios.post(`/api/account/favorite-property/${id}`,data);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// updateStateCommison
export function updateStateCommison(id,data) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.post(`/api/account/update-state-commison/${id}`,data);
      dispatch(slice.actions.getProductSuccess(response.data.property));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPropertyMeli(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/account/meli/get-property/${id}`);
      dispatch(slice.actions.getProductSuccess(response.data.property))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function eventsPorperty(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/account/get-events-property/${id}`);
      dispatch(slice.actions.getEvents(response.data.events))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// propertiesImperium
export function getPropertiesImperium() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://imperium-prod.herokuapp.com/api/properties-sales-and-rent`);
      dispatch(slice.actions.getPropertiesImperium(response.data.properties))
    } catch (error) {
      dispatch(slice.actions.getPropertiesImperium([]))
    }
  };
}
// propertiesImperium
export function getPropertiesBenboot() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-properties-sales-and-rent`);
      dispatch(slice.actions.getPropertiesImperium(response.data.properties))
    } catch (error) {
      dispatch(slice.actions.getPropertiesImperium([]))
    }
  };
}