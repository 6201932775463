// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Switch, Container, Typography, Stack } from '@mui/material';
// _mock_
import { _pricingPlans } from '../_mock';
// components
import Page from '../components/Page';
// sections
import { PricingPlanCard } from '../sections/pricing';

// routes
import { PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------
const hanldeSelectedPlan = (plan) => {
  window.location.href = `${PATH_PAGE.payment}?plan=${plan}`;
}

export default function Pricing() {
  return (
    <Page title="Pricing">
      <RootStyle>
        <Container>
          <Typography variant="h3" align="center" paragraph>
          Es hora de construir tu Agente BenBoot.
          </Typography>
          <Typography align="center" sx={{ color: 'text.secondary' }}>
          Experimente el poder de BenBoot con una prueba gratuita de 30 días o amplíe seleccionando cualquiera de nuestros paquetes de diseño premium y complementos de mercado.
          </Typography>

          <Box sx={{ my: 5 }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              <Typography variant="overline" sx={{ mr: 1.5 }}>
                Mes
              </Typography>
              <Switch />
              <Typography variant="overline" sx={{ ml: 1.5 }}>
                Año (ahorra 10%)
              </Typography>
            </Stack>
            <Typography variant="caption" align="right" sx={{ color: 'text.secondary', display: 'block' }}>
            * Más impuestos aplicables
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {_pricingPlans.map((card, index) => (
              <Grid item xs={12} md={4} key={card.subscription}>
                <PricingPlanCard card={card} index={index} selectedPlan = {hanldeSelectedPlan}/>
              </Grid>
            ))}
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}
