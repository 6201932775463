import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { m } from 'framer-motion';
import { IconButton, Grid, ListItem } from '@mui/material';

import Image from '../Image';

import Iconify from '../Iconify';
import { varFade } from '../animate';

ListImages.propTypes = {
  item: PropTypes.string,
  onRemove: PropTypes.func,
  provided: PropTypes.object,
  snapshot: PropTypes.object,
};
export default function ListImages({ item, onRemove }) {
  return (
      <Grid
        key={item}
        component={m.div}
        {...varFade().inRight}
        sx={{
          p: 0,
          m: 0.5,
          width: 80,
          height: 80,
          borderRadius: 1.25,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Image alt="preview" src={item} ratio="1/1" />
        <IconButton
          size="small"
          onClick={() => onRemove(item)}
          sx={{
            top: 6,
            p: '2px',
            right: 6,
            position: 'absolute',
            color: 'common.white',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon={'eva:close-fill'} />
        </IconButton>
      </Grid>
   
  );
}
