import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const { columnOrder, cards, columns } = action.payload;
      const convertCards = objFromArray(cards);
      const convertColumns = objFromArray(columns);
      state.board = {
        convertCards,
        convertColumns,
        columnOrder,
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.convertColumns = {
        ...state.board.convertColumns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.convertColumns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;
      state.board.convertCards[card.id] = card;
      state.board.convertColumns[columnId].cardIds.push(card.id);
    },
    updateTask(state, action) {
      const { id } = action.payload;
      state.board.convertCards[id] =  action.payload;
    },
    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;
      state.board.convertColumns[columnId].cardIds = state.board.convertColumns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.convertCards = omit(state.board.convertCards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.convertColumns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.convertColumns[columnId];

      state.isLoading = false;
      state.board.convertColumns = omit(state.board.convertColumns, [columnId]);
      state.board.convertCards = omit(state.board.convertCards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/kanban/board');
      dispatch(slice.actions.getBoardSuccess(response.data.board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/addColumn', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatetask(card) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateTask(card));
      await axios.post('/api/kanban/task/updateTask', {
        card,
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function deleteColumn(columnId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/deleteColumn', { columnId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return async () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
    try {
      await axios.post('/api/kanban/task/updateOrderColumns', { newColumnOrder });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return async () => {
    const columnsUpdate = Object.values(columns);
    dispatch(slice.actions.persistCard(columns));
    try {
      await axios.post('/api/kanban/task/updateAllColumns', { columnsUpdate });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return async () => {
    dispatch(slice.actions.addTask({ card, columnId }));
    try {
      await axios.post('/api/kanban/task/addTask', { card,columnId });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return async (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
    try {
      await axios.post('/api/kanban/task/deleteTask', { cardId,columnId });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

