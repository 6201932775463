// routes
/*eslint-disable */

import { PATH_AUTH, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------
// Es aqui
const ICON_SIZE = {
  width: 22,
  height: 22,
};

export const menuLogout = [
  {
    title: 'Inicio',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  }
]
export const menuLogin = [
  {
    title: 'Inicio',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  }
]
