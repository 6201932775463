import { noCase } from 'change-case';
// _mock
import _mock from './_mock';
import { randomNumberRange, randomInArray } from './funcs';

// ----------------------------------------------------------------------

export const _appRelated = ['Chrome', 'Drive', 'Dropbox', 'Evernote', 'Github'].map((appName, index) => ({
  id: _mock.id(index),
  name: appName,
  system: (index === 2 && 'Windows') || (index === 4 && 'Windows') || 'Mac',
  price: index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
  rating: _mock.number.rating(index),
  review: randomNumberRange(999, 99999),
  shortcut: `https://minimal-assets-api.vercel.app/assets/icons/ic_${noCase(appName)}.svg`,
}));

export const _appInstalled = ['de', 'en', 'fr', 'kr', 'us'].map((country, index) => ({
  id: _mock.id(index),
  name: ['Germany', 'England', 'France', 'Korean', 'USA'][index],
  android: randomNumberRange(999, 99999),
  windows: randomNumberRange(999, 99999),
  apple: randomNumberRange(999, 99999),
  flag: `https://minimal-assets-api.vercel.app/assets/icons/ic_flag_${country}.svg`,
}));

export const _appAuthors = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  favourite: randomNumberRange(9999, 19999),
}));

export const _appInvoices = [...Array(5)].map((_, index) => ({
  id: `${Date.now() + index}`,
  price: _mock.number.price(index),
  category: randomInArray(['Android', 'Mac', 'Windows']),
  status: randomInArray(['paid', 'out_of_date', 'in_progress']),
}));


export const _appFeatured = [
  {
    id: '1',
    title: 'Publicaciones en Mercado Libre',
    description: 'Publica tus productos en Mercado Libre y obten una visibilidad global.',
    image: 'https://img.lalr.co/cms/2021/05/04155742/pleito-ppal.jpg?size=xl',
  },
  {
    id: '2',
    title: 'Chat con tus clientes',
    description: 'Convierte tu negocio en una red social de clientes.',
    image: 'https://www.ceupe.com/images/easyblog_articles/2951/b2ap3_large_soporte-tcnico.jpg',
  },
  {
    id: '3',
    title: 'Manejo de finanzas',
    description: 'Maneja tus finanzas con una interfaz amigable.',
    image: 'http://cdn2.dineroenimagen.com/media/dinero/styles/xlarge/public/images/blogs/finanzas-verdes-mexico.jpg',
  }
]