import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../utils/axios';
//
import { dispatch } from './store';

const initialState = {
  isLoading: false,
  error: null,
  clients: [],
  client: {},
  url: '',
  properties: [],
  states: [],
  operations: [],
  code: 'UY',
  types: [],
  newProperties: []
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CLIENTS
    getListClients(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },
    // GET CLIENT EDIT
    editClient(state, action) {
        state.isLoading = false;
        state.client = action.payload;
    },
    // CREATE CLIENT
    createClient(state, action) {
      state.isLoading = false;
      state.clients.push(action.payload);
    },
    // UPDATE CLIENT
    uploadImage(state, action) {
      state.isLoading = false;
      state.url = action.payload;
    },
    deleteClient(state, action) {
      state.isLoading = false;
      state.clients = state.clients.filter(client => client._id !== action.payload);
    },
    // GET PROPERTIES
    getDashboard(state, action) {
      state.isLoading = false;
      state.properties = action.payload.properties;
      state.clients = action.payload.clients;
    },
    // GET PROPERTIES
    getProperties(state, action) {
      state.isLoading = false;
      state.states = action.payload.states;
      state.types = action.payload.types;

    },
    setCode(state, action) {
      state.code = action.payload;
    },
    // GET OPERATIONS
    getOperations(state, action) {
      state.operations = action.payload.operations;
    },
    clearOperations(state) {
      state.operations = [];
    },
    getNewProperties(state, action) {
      state.newProperties = action.payload.newProperties;
    }
  },
});

// Reducer
export default slice.reducer;


export function getListClients() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/api/client/get-list-client`);
        dispatch(slice.actions.getListClients(response.data.clients));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function editClient(body,id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/api/client/edit-client/${id}`, body);
        dispatch(slice.actions.editClient(response.data.client));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function createClient(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/api/client/create-client`, data);
        dispatch(slice.actions.createClient(response.data.client));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function uploadClientImage(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/api/client/upload-client-image`, data);
        dispatch(slice.actions.uploadImage(response.data.urlImage));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function deleteClient(id) {
    return async () => {
      dispatch(slice.actions.deleteClient(id));
    };
}
export function getDashboard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/app/get-dashboard`);
      dispatch(slice.actions.getDashboard(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProperties() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const { data } = await axios.get('https://api.ipregistry.co/?key=q44qmr4b2001lm05')
      dispatch(slice.actions.setCode('UY')); // data.location.country.code
      const response = await axios.get(`/api/portal/home/get-properties?code=${'UY'}`); // data.location.country.code
      dispatch(slice.actions.getProperties(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOperations(id) {
  return async () => {
    try {
      dispatch(slice.actions.clearOperations());
      const response = await axios.get(`/api/portal/home/get-operation/${id}`);
      dispatch(slice.actions.getOperations(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// getPropertiesToday
export function getPropertiesToday(params) {
  return async () => {
    try {
      const response = await axios.get(`/api/account/get-properties-today?${params}`);  
      dispatch(slice.actions.getNewProperties(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}