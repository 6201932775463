// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  invoice: getIcon('ic_invoice'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  portal: getIcon('ic_portal'),
  blog: getIcon('ic_blog'),
  search: getIcon('ic_search'),
  ic_agent: getIcon('ic_agent'),
  map: getIcon('ic_map'),
};

const navConfig = () => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Administración',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Clientes',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.user.list },
          { title: 'nuevo', path: PATH_DASHBOARD.user.newUser },
        ],
      },
      {
        title: 'Agentes',
        path: PATH_DASHBOARD.agents.root,
        icon: ICONS.ic_agent,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.agents.list },
          { title: 'nuevo', path: PATH_DASHBOARD.agents.new },
        ],
      },
      {
        title: 'Inmuebles',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'nuevo', path: PATH_DASHBOARD.eCommerce.newProduct },
        ],
      },
      // INVOICE
      {
        title: 'Facturación',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.invoice.list },
        ],
      },
    ],
  },
  {
    subheader: 'Información',
    items: [
      {
        title: 'Blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.blog.posts },
          { title: 'nuevo', path: PATH_DASHBOARD.blog.new },
          { title: 'Mis Posts', path: PATH_DASHBOARD.blog.myPosts },
        ],
      },
      {
        title: 'Mapa de Precios',
        path: PATH_DASHBOARD.pricemap.root,
        icon: ICONS.map,
        info: (
          <Label variant="outlined" color="success">
            Nuevo
          </Label>
        ),
      },
    ]
  },
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Aplicación',
    items: [
      {
        title: 'Captación',
        path: PATH_DASHBOARD.search.root,
        icon: ICONS.search,
        children: [
          { title: 'Buscar', path: PATH_DASHBOARD.search.list },
          { title: 'Seleccionados', path: PATH_DASHBOARD.search.mySearches},
        ],
      },

      { title: 'Calendario', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'Notas',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
      },
    ],
  },
];

export default navConfig;
