export const countries = [
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brasil', phone: '55' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
];
