import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  properties: [],
  product: null,
  sortBy: null,
  filters: [],
  availableFilters: [],
  paging: {
    total: 0,
    primary_results: 0,
    offset: 0,
    limit: 0
  },  
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.properties = [];
      state.error = null;
      state.filters = [];
      state.availableFilters = [];
      state.paging = {
        total: 0,
        primary_results: 0,
        offset: 0,
        limit: 0

      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getFilterProperties(state, action) {
      state.isLoading = false;
      state.properties = action.payload.properties;
      state.filters = action.payload.filters;
      state.paging = action.payload.paging;
      state.availableFilters = action.payload.available_filters;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getFilterProperties(params) {
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-filter-properties?${params}`);  
      dispatch(slice.actions.getFilterProperties(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
